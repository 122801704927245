import axios from "axios";
import swal from "sweetalert";

const serviceURL = process.env.REACT_APP_SERVICE_URL;

const instance = axios.create({
  baseURL: serviceURL,
});

const secureInstance = axios.create({
  baseURL: serviceURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
});

export class BaseService {
  Login = async (body) => {
    try {
      const login = await instance.post("/auth/login", body);
      if (login.data.data) {
        localStorage.setItem("access_token", login.data.data.access_token);
        localStorage.setItem("refresh_token", login.data.data.refresh_token);
      }
      return login;
    } catch (error) {
      console.log(error, "  error");
      swal("Oops!",error?.response?.data?.message, "error");
    }
  };

  SignUp = async (body) => {
    try {
      const signUp = await instance.post("/auth/register", body);
      if (signUp.data.data) {
        localStorage.setItem("access_token", signUp.data.data.access_token);
        localStorage.setItem("refresh_token", signUp.data.data.refresh_token);
        localStorage.setItem("role", signUp.data.data.user.role);
      }
      return signUp;
    } catch (error) {
      swal("Oops!",error?.response?.data?.message, "error");
      console.log(error, "  error in service");
    }
  };

  forgotPassword = async (body) => {
    try {
      const signUp = await instance.post("/auth/forgot-password", body);
      return signUp;
    } catch (error) {
      alert(error);
      console.log(error, "  error in service");
    }
  };

  getPlans = async () => {
    try {
      return await secureInstance.get("/prices");
    } catch (error) {
      alert(error);
    }
  };

  getPreferences = async () => {
    try {
      return await secureInstance.get("/profile/preferences");
    } catch (error) {
      // alert(error)
      throw error;
    }
  };
  getSubscription = async () => {
    try {
      return await secureInstance.get("/subscription");
    } catch (error) {
      // alert(error)
      throw error;
    }
  };

  verifyOtp = async (token) => {
    try {
      const otp = await instance.get("/auth/check/token/" + token);
      return otp;
    } catch (error) {
      console.log(error, "  error");
      throw error;
    }
  };

  recoverPassword = async (body, otp) => {
    try {
      const passwordReset = await instance.put(
        "/auth/recover-password/" + otp,
        body
      );
      return passwordReset;
    } catch (error) {
      console.log(error, "  error");
      throw error;
    }
  };

  updatePassword = async (body, otp) => {
    try {
      const passwordReset = await secureInstance.patch("/profile", body);
      return passwordReset;
    } catch (error) {
      return error;
    }
  };
  updateProfile = async (body) => {
    try {
      const passwordReset = await secureInstance.put("/profile", body);
      return passwordReset;
    } catch (error) {
      console.log(error, "  error");
      alert(error);
    }
  };
  updatePreferrences = async (body) => {
    try {
      const passwordReset = await secureInstance.put("/profile/preferences", body);
      return passwordReset;
    } catch (error) {
      console.log(error, "  error");
      alert(error);
    }
  };

  getPaymentHistory = async (data) => {
    try {
      return await secureInstance.get(`/wallet/history?filter=${data}`);
    } catch (error) {
      throw error;
    }
  };
  getProfile = async (data) => {
    try {
      return await secureInstance.get(`/profile`);
    } catch (error) {
      throw error;
    }
  };

  createSubscription = async (body) => {
    try {
      const subscription = await secureInstance.post("/subscription", body);
      return subscription;
    } catch (error) {
      console.log(error, "  error");
      alert(error);
    }
  };

  addPaymentMethod = async (body) => {
    try {
      const subscription = await secureInstance.post("/wallet", body);
      return subscription;
    } catch (error) {
      console.log(error, "  error");
      alert(error);
    }
  };

  getPaymentMethods = async () => {
    try {
      return await secureInstance.get("/wallet");
    } catch (error) {
      // alert(error)
      throw error;
    }
  };
 
}
export const formatDate = (dateTimeStr) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = new Date(dateTimeStr);
  return date.toLocaleDateString(undefined, options);
};

export const formatTimeRange = (dateTimeStr) => {
  const date = new Date(dateTimeStr);
  const startTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  // Assuming you want a 2-hour time range from the start time
  date.setHours(date.getHours() + 2);
  const endTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${startTime}-${endTime}`;
};
