import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { BaseService } from "../../service";

const Language = ({ preference, setUpdatePreference, updatePreference }) => {
  const service = new BaseService();

  const languages = [
    { name: "English", code: "english" },
    { name: "Arabic", code: "arabic" },
    { name: "Duch", code: "duch" },
    { name: "Urdu", code: "urdu" },
    { name: "Hindi", code: "hindi" },
  ];

  const filtered = languages.filter(
    (item) => item.name.toLowerCase() == preference?.language.toLowerCase()
  );
  const [selectedLanguage, setSelectedLanguage] = useState();

  const onLanguageChange = async (e) => {
    setSelectedLanguage(e.value);
    setUpdatePreference({
      ...updatePreference,
      language: e.value.code,
    });
  };
  useEffect(() => {
    setSelectedLanguage(filtered && filtered.length > 0 && filtered[0]);
  }, []);

  return (
    <>
      <div className="language">
        <label>Choose output langauge</label>
        <Dropdown
          className="dropdown-language"
          value={selectedLanguage}
          options={languages}
          onChange={onLanguageChange}
          optionLabel="name"
          placeholder="Select Language"
        />
      </div>
    </>
  );
};

export default Language;
