import { useState, useRef, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { BaseService } from "../../service";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import jwt_decode from "jwt-decode";

const CheckoutForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [cardHolderName, setcardHolderName] = useState();

  const [name, setName] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const service = new BaseService();

  const handleCreateSubscription = async (paymentMethodId) => {
    console.log("Subscription function is called===>", paymentMethodId);
    const priceId = localStorage.getItem("paymentId");
    console.log("Price Id===>", priceId);
    const subscriptionObj = {
      price_id: localStorage.getItem("paymentId"),
      payment_method: "card",
      payment_details: {
        name,
      },
      payment_method_id: paymentMethodId,
    };
    const subscribed = await service.createSubscription(subscriptionObj);

    if (subscribed && subscribed.data) {
      // navigate("payment-history");
      setVisible(true);
      console.log("Subscribed===>", subscribed);
    }
  };
  const handleAddPaymentMethod = async (paymentId) => {
    const paymentObj = {
      payment_method_id: paymentId,
    };
    const response = await service.addPaymentMethod(paymentObj);
    console.log("Subscription called", response);

    if (response?.status == 200) {
      console.log("Subscription called inside");
      handleCreateSubscription(paymentId);
    }
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsLoading(false);
      return;
    }

    let cardElement = null;
    cardElement = { ...elements.getElement(CardCvcElement) };
    cardElement = { ...cardElement, ...elements.getElement(CardExpiryElement) };
    cardElement = { ...cardElement, ...elements.getElement(CardNumberElement) };

    const { error: stripeError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

    if (stripeError) {
      // NotificationManager.error(stripeError.message);
      console.log("Error from Stripe==>1", stripeError.message);
      return;
    } else {
      handleAddPaymentMethod(paymentMethod.id);
      console.log("Payment method ", paymentMethod);
      console.log("Payment method ID", paymentMethod.id);
    }

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      // NotificationManager.error(stripeError.message);
      console.log("Error from Stripe==>", stripeError.message);
      return;
    }

    // Show a success message to your customer
    // There's a risk of the customer closing the window before callback
    // execution. Set up a webhook or plugin to listen for the
    // payment_intent.succeeded event that handles any business critical
    // post-payment actions.
    // NotificationManager.success('We have receved your payment');
    console.log("Success message payment");
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#333",
        fontSize: "16px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        "::placeholder": {
          color: "#999",
        },
        padding: "10px",
        borderRadius: "4px",
        backgroundColor: "#fff",
        border: "1px solid #f1592a",
      },
    },
  };

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const userData = jwt_decode(access_token);
      console.log(userData, " data");
      setName(userData.user.first_name);
    }
  }, []);
  const headerContent = (
    <div style={{ textAlign: "center" }}>
      <img src="/assets/img/goodTick.svg" alt="Ok" />
    </div>
  );
  const footerContent = (
    <div className="gotit-btn">
      <Button
        label="OK, GOT IT!"
        icon="pi pi-check"
        onClick={() => {
          setVisible(false);
          setIsLoading(false);
        }}
        autoFocus
      />
    </div>
  );
  return (
    <>
      <div>
        <div>
          <div>
            <form id="payment-form" onSubmit={handleSubmit}>
              <div className="cont-div">
                <label htmlFor="Card Holder Name" className="stripe-label">
                  Card Holder Name
                </label>
                <input
                  placeholder="John Mike"
                  onChange={(e) => setcardHolderName(e.target.value)}
                  type="text"
                  title="Card Holder Name"
                  className="user-name-input"
                />
              </div>

              <label htmlFor="card-number" className="stripe-label">
                Card Number
              </label>
              <div style={{ position: "relative" }}>
                <CardNumberElement
                  options={cardElementOptions}
                  className="stripe-input-style"
                />
              </div>
              <div className="main-cont-cvc">
                <div className="w-48">
                  <label htmlFor="card-expiry" className="stripe-label">
                    Expiry
                  </label>
                  <CardExpiryElement
                    id="card-expiry"
                    options={cardElementOptions}
                    className="stripe-input-style"
                  />
                </div>
                <div className="w-48">
                  <label htmlFor="card-cvc" className="stripe-label">
                    CVC Number
                  </label>
                  <CardCvcElement
                    id="card-cvc"
                    options={cardElementOptions}
                    className="stripe-input-style"
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  variant={"outlined"}
                  type="submit"
                  disabled={isLoading}
                  className="proceed-to-pay"
                >
                  {isLoading ? (
                    <div>Loading please wait </div>
                  ) : (
                    <div>Pay To Proceed</div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Dialog
        header={headerContent}
        visible={visible}
        style={{ width: "27vw", textAlign: "center" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <h3>Payment Confirmed</h3>
        <p className="m-0">
          Unleash the Future of Typing with our AI Keyboard Extension. Best of
          Luck!
        </p>
      </Dialog>
    </>
  );
};

export default CheckoutForm;
