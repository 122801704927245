import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

const CommunicationStyle = ({
  preference,
  setUpdatePreference,
  updatePreference,
}) => {
  const languages = [
    { name: "Direct", code: "direct" },
    { name: "Professional", code: "professional" },
    { name: "Family", code: "friendly" },
    { name: "Official", code: "official" },
  ];
  const filtered = languages.filter(
    (item) => item.code.toLowerCase() === preference?.style?.toLowerCase()
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    filtered && filtered.length > 0 && filtered[0]
  );

  const onLanguageChange = (e) => {
    setSelectedLanguage(e.value);
    setUpdatePreference({
      ...updatePreference,
      style: e.value.code,
    });
  };
  useEffect(() => {
    setSelectedLanguage(filtered && filtered.length > 0 && filtered[0]);
  }, []);

  return (
    <>
      <div className="language">
        <label>Communication Style</label>
        <Dropdown
          className="dropdown-language"
          value={selectedLanguage}
          options={languages}
          onChange={onLanguageChange}
          optionLabel="name"
          placeholder="Choose an option for communication output"
        />
      </div>
    </>
  );
};

export default CommunicationStyle;
