import { useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Steps } from "primereact/steps";

import Past from "./past";
import Upcoming from "./upcoming";
import Current from "./current";

const PaymentHistory = () => {
  

  const [activeIndex, setActiveIndex] = useState(0);
  const [current, ] = useState();
  const [past, ] = useState();
  const [future, ] = useState();
  const [loading, ] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const items = [
    {
      label: "Past",
    },
    {
      label: "Current",
    },
    {
      label: "Upcoming",
    },
  ];

//   useEffect(() => {
//     setLoading(true);
//     const getHistory = async () => {
//       const history = await service.getPaymentHistory();
//       if (history && history.data && history.data.data) {
//         console.log(history.data);
//         const date = new Date();
//         const currentMonth = date.getMonth();
//         const currentList = [],
//           pastList = [],
//           futureList = [];

//         history.data.data.map((item) => {
//           const paymentDate = new Date(item.createdAt);
//           const paymentMonth = paymentDate.getMonth();
//           console.log(paymentMonth, " payment month");
//           if (currentMonth == paymentMonth) {
//             currentList.push(item);
//           }
//           if (currentMonth > paymentMonth) {
//             pastList.push(item);
//           }
//           if (currentMonth < paymentMonth) {
//             futureList.push(item);
//           }
//         });
//         console.log(
//           currentList,
//           pastList,
//           futureList,
//           "  all lists separately",
//           currentMonth
//         );
//         setCurrent(currentList);
//         setFuture(futureList);
//         setPast(pastList);
//         setLoading(false);
//       } else {
//         setLoading(false);
//       }
//     };
//     getHistory();
//   }, []);

  return (
    <div>
      <div>
        <Sidebar open={isDrawerOpen} onClose={handleDrawerToggle} />
      </div>
      <div className="custom-side">
        <div className="setting-body">
          <div className="d-flex align-items-center">
            <button
              onClick={handleDrawerToggle}
              className="icon-button desktop-hide"
            >
              <img
                src="/assets/img/menu.png"
                alt="Menu Icon"
                className="icon"
              />
            </button>
            <h2>Payment History</h2>
          </div>
          <Steps
            model={items}
            activeIndex={activeIndex}
            onSelect={(e) => setActiveIndex(e.index)}
            readOnly={false}
          />

          <div className="step-body">
            {activeIndex === 0 && <Past pastList={past} />}
            {activeIndex === 1 && <Current currentList={current} />}
            {activeIndex === 2 && <Upcoming futureList={future} />}
          </div>
        </div>
        {loading && <div id="loader">{/* <ProgressSpinner /> */}</div>}
      </div>
    </div>
  );
};

export default PaymentHistory;
