import { useState } from "react";
import { BaseService } from "../../service";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {

    const service = new BaseService();

    const [email, setEmail] = useState();
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setSubmit(true);
        setLoading(true);
        if (email) {

            try {
                const forgotPassword = await service.forgotPassword({ email });
                if (forgotPassword && forgotPassword.data) {
                    setLoading(false);
                    navigate('/otp', { state: { email } })
                }
            } catch (error) {
                alert(error);
            }
        }
    }

    return (
        <div style={{ textAlign: 'center' }}>

            <div className='logo'>
                <img src="/assets/img/saiy-logo.png" alt='SaiY' />
            </div>

            <br />
            <br />
            <br />

            <h1 className="login-heading">Reset Password</h1>

            <p className="login-subheading">Please enter your email address</p>

            <div className="login-form">
                <label>Email Address</label>
                <input onChange={(e) => setEmail(e.target.value)} type="email" title="Email address" style={{ borderColor: `${submit && !email ? 'red' : '#F7F7F9'}` }} />
                {submit && !email &&
                    <label style={{ color: "red", marginTop: 0 }}>Please enter email address.</label>
                }
                <button style={{ backgroundColor: `${email ? '#035793' : '#7D848D'}` }} onClick={() => handleSubmit()}>Send OTP</button>
            </div>
            {loading && <div id="loader">
                {/* <ProgressSpinner /> */}
            </div>}
        </div>
    );


};

export default ResetPassword;