import { useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Steps } from "primereact/steps";
import Language from "./language";
import CommunicationStyle from "./communication";
import Message from "./message";
import { useEffect } from "react";
import swal from "sweetalert";
import { BaseService } from "../../service";

const Preferences = () => {
  const service = new BaseService();
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [preference, setPreference] = useState({
    user_id: null,
    length: 100,
    language: "english",
    dialect: "american",
    style: "Family",
  });
  const [updatePreference, setUpdatePreference] = useState({
    dialect: "american",
  });

  useEffect(() => {
    setLoading(true);
    try {
      async function getPreferences() {
        const preferences = await service.getPreferences();
        if (preferences.data) {
          setLoading(false);
          setPreference(preferences?.data?.data);
        }
      }
      getPreferences();
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  }, []);
  const items = [
    {
      label: "Language",
    },
    {
      label: "Message Length",
    },
    {
      label: "Communication Style",
    },
  ];
  const handleUpdatePreferrences = async () => {
    const preferences = await service.updatePreferrences(updatePreference);
    console.log("preferencesjbakjsfbjka", preferences);
    if (preferences?.data?.message) {
      swal({
        title: "Preferences Updated",
        text: "Preferences updated successfully!",
        icon: "success",
        button: "Ok",
      });
    }
  };
  useEffect(() => {
    if (
      updatePreference?.dialect != null &&
      updatePreference?.style != null &&
      updatePreference?.length != null &&
      updatePreference?.language != null
    ) {
      handleUpdatePreferrences();
      console.log("called");
    }
    console.log("insode");
  }, [updatePreference]);

  console.log("updatePreference updatePreference", preference);
  return (
    <div>
      <div>
        <Sidebar open={isDrawerOpen} onClose={handleDrawerToggle} />
      </div>
      <div className="custom-side">
        <div className="setting-body">
          <div className="d-flex align-items-center">
            <button
              onClick={handleDrawerToggle}
              className="icon-button desktop-hide"
            >
              <img
                src="/assets/img/menu.png"
                alt="Menu Icon"
                className="icon"
              />
            </button>
            <h2>Preferences</h2>
          </div>
          <Steps
            itemID="399"
            model={items}
            activeIndex={activeIndex}
            onSelect={(e) => setActiveIndex(e.index)}
            readOnly={false}
          />

          <div className="step-body">
            {activeIndex === 0 && (
              <Language
                preference={preference}
                setUpdatePreference={setUpdatePreference}
                updatePreference={updatePreference}
              />
            )}
            {activeIndex === 1 && (
              <Message
                preference={preference}
                setUpdatePreference={setUpdatePreference}
                updatePreference={updatePreference}
              />
            )}
            {activeIndex === 2 && (
              <CommunicationStyle
                preference={preference}
                setUpdatePreference={setUpdatePreference}
                updatePreference={updatePreference}
              />
            )}
          </div>
        </div>
        {loading && <div id="loader">{/* <ProgressSpinner /> */}</div>}
      </div>
    </div>
  );
};

export default Preferences;
