import React, { useState } from "react";

const Message = ({ preference, setUpdatePreference, updatePreference }) => {
  const [message, setMessage] = useState(preference?.length);

  return (
    <>
      <div>
        <div className="setting-form-item" style={{ display: "grid" }}>
          <label style={{ fontWeight: "bold" }}>No. of characters</label>
          <input
            value={message}
            style={{ width: "500px" }}
            placeholder="Characters length"
            type="number"
            onChange={(e) => {
              setMessage(e.target.value);
              setUpdatePreference({
                ...updatePreference,
                length: e.target.value,
              });
            }}
          />
        </div>
        <div className="info-msg">
          {/* <label className="fi-rr-exclamation"></label> */}
          <p>
            The keyboard will automatically detect the number of words after you
            type a minimum of 100 words. Results will be displayed accordingly
          </p>
        </div>
      </div>
    </>
  );
};

export default Message;
