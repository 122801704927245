import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Routes, Route } from "react-router-dom";
import OnBoarding from './Components/Onboarding';
import Login from './Components/login';
import ResetPassword from './Components/login/resetPassword';
import OTP from './Components/login/otp';
import NewPassword from './Components/login/newPassword';
import SignUp from './Components/signUp';
import PricingPlan from './Screens/pricingPlan/pricing';
import PaymentMethod from './Screens/payment/paymentMethod';
import AddNewCard from './Screens/payment/addCard';
import Settings from './Screens/Settings';
import Preferences from './Screens/Preferences';
import Subscription from './Screens/Subscription';
import PaymentHistory from './Screens/PaymentHistory';
import Statistics from './Screens/Statistics';

function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<OnBoarding />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/pricing" element={<PricingPlan />} />
        <Route path="/payment-method" element={<PaymentMethod />} />
        <Route path="/new-card" element={<AddNewCard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/preferences" element={<Preferences />} />
        <Route path="/subscriptions" element={<Subscription />} />
        <Route path="/payment-history" element={<PaymentHistory />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/dashboard" element={<Settings />} />
      </Routes>
    </>
  );
}

export default App;
