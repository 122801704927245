import jwt_decode from "jwt-decode";
import { useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useEffect } from "react";
import swal from "@sweetalert/with-react";
import { BaseService } from "../../service";
import { useNavigate, useSearchParams } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const service = new BaseService();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleSubmit = async () => {
    if (name && email) {
      try {
        const resetProfile = await service.updateProfile({
          first_name: name,
          email: email,
        });
        console.log("Namee", resetProfile);
        if (resetProfile.data.message == "Success") {
          swal({
            title: "Profile Update!",
            text: "Profile updated successfully!",
            icon: "success",
            button: "Ok",
          });
          //"Profile Update", "Profile updated successfully!", "success");
        } else {
          swal({
            title: "Error",
            text: "Something went wrong!",
            icon: "error",
            button: "Ok",
          });
        }
      } catch (error) {
        console.log("error", error);
        swal("Something went wrong!", error, "error");
      }
    } else {
      alert("Please fill the form");
    }
  };
  const handleResetPassword = async () => {
    if (currentPassword && newPassword) {
      const resetPassword = await service.updatePassword({
        password: newPassword,
        old_password: currentPassword,
      });
      if (!resetPassword) {
        swal("Something went wrong please try again.", "error");
      } else {
        swal(resetPassword?.response?.data?.message, "error");
      }

      console.log("resetttt", resetPassword);
    } else {
      swal(
        "Please fill the both fields current password and new password",
        "error",
      );
    }
  };
  const switchFunctions = () => {
    if (currentPassword) {
      handleResetPassword();
    } else if (name) {
      handleSubmit();
    } else if (currentPassword) {
      handleResetPassword();
    } else {
      swal("Something went wrong!", "error");
    }
  };
  const getProfileData = async () => {
    const profileData = await service.getProfile();
    console.log("Profole data==.", profileData?.data?.data);
    if (profileData?.data?.message == "Success") {
      setName(profileData?.data?.data?.first_name);
      setEmail(profileData?.data?.data?.email);
    }
  };

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const userData = jwt_decode(access_token);
      console.log("userData", userData.user);
    }
    getProfileData();
  }, []);

  useEffect(() => {
    const newUser = searchParams.get("newUser") === "true";
    if (newUser) {
      swal({
        title: "Thank you for choosing SAiY",
        content: (
          <p>
            Your current package is - <strong>FREE</strong> , to upgrade your
            package please click{" "}
            <span onClick={() => navigate("/subscriptions")}>here</span>
          </p>
        ),
        icon: "success",
        button: {
          text: "Close",
          closeModal: true,
        },
      });
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("newUser");
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, navigate]);

  return (
    <div>
      <div>
        <Sidebar open={isDrawerOpen} onClose={handleDrawerToggle} />
      </div>
      <div className="custom-side">
        <div className="setting-body">
          <div className="d-flex align-items-center">
            <button
              onClick={handleDrawerToggle}
              className="icon-button desktop-hide"
            >
              <img
                src="/assets/img/menu.png"
                alt="Menu Icon"
                className="icon"
              />
            </button>
            <h2>Account Settings</h2>
          </div>
          <div className="setting-form">
            <label>Name</label>
            <input
              placeholder="Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label>Email</label>
            <input
              disabled
              type="text"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Current Password</label>
            <input
              type="password"
              placeholder="Enter password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <label>New Password</label>
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button onClick={() => switchFunctions()}> Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
