import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { RadioButton } from "primereact/radiobutton";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseService } from "../../service";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
const PaymentMethod = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const service = new BaseService();
  const [name, setName] = useState();
  const [value, setValue] = useState();
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const userData = jwt_decode(access_token);
      console.log(userData, " data");
      setName(userData.user.first_name);
    }

    async function methods() {
      const data = await service.getPaymentMethods();
      if (data && data.data) {
        setPaymentMethods(data.data?.data);
      }
    }
    methods();
  }, []);

  const handleSubmit = async () => {
    setSubmit(true);
    setLoading(true);
    const subscriptionObj = {
      price_id: localStorage.getItem("paymentId"),
      payment_method: "google-pay",
      payment_details: {
        name,
      },
      payment_method_id: "",
    };

    // if (value === "card") {
    //     subscriptionObj['payment_method_id'] = "";
    // }

    const subscribed = await service.createSubscription(subscriptionObj);

    if (subscribed && subscribed.data) {
      navigate("payment-history");
    }
    setLoading(false);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div className="logo">
        <img src="/assets/img/saiy-logo.png" alt="SaiY" />
      </div>

      <br />

      <h1 className="login-heading">Payment Methods</h1>

      <p className="login-subheading">
        Choose the desired payment method <br /> which suits you best.
      </p>

      <div className="login-form">
        {/* <div className="payment-methods">
                    <div className="single-card">
                        <img src="/assets/img/masterCard.png" alt="MasterCard" />
                        <label>Mastercard</label>
                    </div>
                    <RadioButton style={{ marginTop: "11px", marginRight: '20px' }} value="val1" name="city" onChange={(e) => setValue(e.value)} checked={value === 'val1'} />
                </div>

                <div className="payment-methods">
                    <div className="single-card">
                        <img src="/assets/img/paypal.png" alt="Paypal" />
                        <label>PayPal</label>
                    </div>
                    <RadioButton value="val2" style={{ marginTop: "11px", marginRight: '20px' }} name="city" onChange={(e) => setValue(e.value)} checked={value === 'val2'} />
                </div> */}


        {/* <div className="payment-methods">
                    <div className="single-card">
                        <img src="/assets/img/visa.png" alt="Visa" />
                        <label>Visa card</label>
                    </div>
                    <RadioButton value="val5" style={{ marginTop: "11px", marginRight: '20px' }} name="city" onChange={(e) => setValue(e.value)} checked={value === 'val5'} />
                </div> */}

        <label
          onClick={() => window.location.replace("new-card")}
          style={{ color: "#035793", cursor: "pointer" }}
        >
          + Add New Card
        </label>

        <button
          style={{ backgroundColor: "#035793", marginBottom: "5rem" }}
          onClick={() => handleSubmit()}
        >
          Proceed To Pay
        </button>
      </div>
      {loading && <div id="loader" />}
    </div>
  );
};

export default PaymentMethod;
