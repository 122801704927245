import React, { useEffect } from "react";
import { BaseService } from "../../service";
import { useState } from "react";

function CardDescription({ title }) {
  return (
    <div className="card-description">
      <h2 style={{ border: "1px solid" }}>{title}</h2>
    </div>
  );
}

function CardBilling({ price }) {
  return (
    <div className="card-billing">
      <p className="price">${price}</p>
      <p className="per-month">User/Month</p>
    </div>
  );
}

function CardFeatures({ data }) {
  return (
    <div className="card-features">
      {data.map((item, index) => {
        return (
          <>
            {item.available ? (
              <div style={{ lineHeight: "16px" }} className="available">
                <img
                  style={{ height: "fit-content", marginLeft: "10px",marginTop:"4px" }}
                  src="/assets/img/ok.png"
                  alt="saiY"
                />
                <label className="cardLabelPrice" key={index}>
                  {item.title}
                </label>
              </div>
            ) : (
              <div style={{ lineHeight: "16px" }} className="not-available">
                <img
                  style={{ height: "fit-content", marginLeft: "10px",marginTop:"4px" }}
                  src="/assets/img/cross.png"
                  alt="saiY"
                />
                <label className="cardLabelPrice" key={index}>
                  {item.title}
                </label>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}

function CardAction({ hanbleChoosePlan, paymentId }) {
  const handleChoose = (id) => {
    hanbleChoosePlan(id);
    localStorage.setItem("paymentId", paymentId);
    localStorage.setItem("paymentId", paymentId);
    window.location.replace("payment-method");
  };
  return (
    <div className="card-action">
      <button onClick={() => handleChoose(paymentId)}>Choose Plan</button>
    </div>
  );
}

function PricingCard(props) {
  const service = new BaseService();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      async function plansFun() {
        const plansData = await service.getPlans();
        plansData.data.data.shift();
        setPlans(plansData.data.data);
        setLoading(false);
        // eslint-disable-next-line array-callback-return
        plansData.data?.data?.map((item) => {
          if (item.name === "Advanced") {
            localStorage.setItem("paymentId", item.id);
            // props?.handleSetAdvance(item.id);
          }
        });
      }
      plansFun();
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  }, []);

  const cardsData = [
    {
      id: 2,
      type: "basic",
      title: "Basic",
      price: 40,
      recurrency: 24.99,
      mostPopular: false,
      data: [
        "App Management",
        "Attendance Management",
        "Leave System Management",
      ],
      disabledData: [
        "Employee Management",
        "Expense Tracking",
        "Chat Support",
        "Invoice Generate",
        "Payroll",
        "Payroll",
      ],
    },
    {
      id: 3,
      type: "medium",
      title: "Advance",
      price: 90,
      recurrency: 59.99,
      mostPopular: false,
      data: [
        "App Management",
        "Attendance Management",
        "Leave System Management",
        "Employee Management",
        "Expense Tracking",
        "Chat Support",
        "Invoice Generate",
      ],
      disabledData: ["Payroll", "Payroll"],
    },
    {
      id: 4,
      type: "pro",
      title: "Premium",
      price: 110,
      recurrency: 84.99,
      mostPopular: false,
      data: [
        "App Management",
        "Attendance Management",
        "Leave System Management",
        "Employee Management",
        "Expense Tracking",
        "Chat Support",
        "Invoice Generate",
        "Payroll",
        "Payroll",
      ],
      disabledData: [],
    },
  ];

  function changeBackground(e) {
    if (e.target === "div.card") e.target.style.height = "40rem";
  }

  const handleChoosePlan = (paymentId) => {
    // props?.handleSetAdvance(paymentId);
  };
  return (
    <div
      style={{
        textAlign: "center",
        height: `${props?.headingDisplay === 1 ? "auto" : "auto"}`,
      }}
      onMouseOver={changeBackground}
    >
      <div
        style={{ display: `${props?.headingDisplay === 1 ? "none" : ""}` }}
        className="logo"
      >
        <img src="/assets/img/saiy-logo.png" alt="SaiY" />
      </div>

      <br style={{ display: `${props?.headingDisplay === 1 ? "none" : ""}` }} />

      <h1
        style={{ display: `${props?.headingDisplay === 1 ? "none" : ""}` }}
        className="login-heading"
      >
        Choose a plan
      </h1>

      <p
        style={{ display: `${props?.headingDisplay === 1 ? "none" : ""}` }}
        className="login-subheading"
      >
        Please select a subscription plan
      </p>
      <div className="pricing-scroll-container">
        <div className="pricing-container">
          {plans &&
            plans.length > 0 &&
            plans.map((item) => {
              return (
                <div className={`card pricing-card ${item.type}`}>
                  {item.mostPopular ? (
                    <span className="most-popular">Most Popular</span>
                  ) : null}
                  <CardDescription title={item.name} />
                  <CardBilling price={item.price} />
                  <CardFeatures data={item.features} />
                  <CardAction
                    hanbleChoosePlan={(id) => handleChoosePlan(id)}
                    paymentId={item.id}
                    //   paymentMethodId= {item.}
                  />
                  <label className="trial">Start 3 Days Free Trial</label>
                </div>
              );
            })}
        </div>
      </div>
      {loading && <div id="loader">{/* <ProgressSpinner /> */}</div>}
    </div>
  );
}

export default PricingCard;
