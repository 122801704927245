import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import jwt_decode from "jwt-decode";

function OnBoarding(props) {
  const [step, setStep] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 767);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const onboardingData = [
    {
      leftCenter: "onboarding-txt1.png",
      nextBtn: "onboarding1.png",
      skip: "Skip",
      rightTop: (
        <label>
          SAiY
          <span>
            <img src="/assets/img/trademark2.png" alt="TM" />
          </span>{" "}
          Hello to Your new Web communication
        </label>
      ),
      rightCenterBackground: "",
      rightCenter: "onboard1-right-center.png",
    },
    {
      leftCenter: "onboardingLeftCenter2.png",
      nextBtn: "2.png",
      rightTop: (
        <label>
          SAiY
          <span>
            <img src="/assets/img/trademark2.png" alt="TM" />
          </span>{" "}
          Hello to Your new Web communication
        </label>
      ),
      rightCenterBackground: "onboardingCenterBackground2.png",
      rightCenter: "onboardingCenter2.png",
    },
    {
      leftCenter: "onboardingLeftCenter3.png",
      nextBtn: "3.png",
      rightTop: (
        <label>
          SAiY
          <span>
            <img src="/assets/img/trademark2.png" alt="TM" />
          </span>{" "}
          Hello to Your new Web communication
        </label>
      ),
      rightCenterBackground: "rightCenterBackground3.png",
      rightCenter: "rightCenter3.png",
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");

    if (token && refreshToken) {
      let decodedToken = jwt_decode(token);
      console.log("Decoded Token", decodedToken);
      let currentDate = new Date();

      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.clear();
        window.location.replace("/");
      } else {
        window.location.replace("/subscriptions");
      }
    }
  }, []);

  const handleClick = () => {
    if (step === 2) {
      window.location.replace("login");
      return;
    }

    setStep(step + 1);
  };

  return (
    <>
      {isDesktop ? (
        <Container
          style={{
            marginRight: "0px",
            paddingRight: "0px",
            marginLeft: "20px",
            maxWidth: "none",
          }}
        >
          <Row style={{ marginRight: "0px" }}>
            <Col sm={5}>
              <div className="logo">
                <img src="/assets/img/saiy-logo.png" alt="SaiY" />
              </div>
              <div className="onboard-left-center-txt">
                <img
                  src={`/assets/img/${onboardingData[step].leftCenter}`}
                  style={{ width: "-webkit-fill-available" }}
                  alt="SaiY"
                />
              </div>
              <div className="onboard-bottom-row">
                <label onClick={() => window.location.replace("login")}>
                  Skip
                </label>
                <img
                  src={`/assets/img/${onboardingData[step].nextBtn}`}
                  onClick={() => handleClick()}
                  alt="SaiY"
                />
              </div>
            </Col>
            <Col sm={7} style={{ backgroundColor: "#035793", height: "100vh" }}>
              <div className="onboard-right-top">
                {onboardingData[step].rightTop}
              </div>
              {step === 0 ? (
                <div className="onboard-right-center-txt">
                  <img
                    src={`/assets/img/${onboardingData[step].rightCenter}`}
                    alt="SaiY"
                  />
                </div>
              ) : (
                <div>
                  {step === 0 ? (
                    <img
                      src={`/assets/img/${onboardingData[step].rightCenter}`}
                      alt="SaiY"
                    />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <div className="right-center">
                        <img
                          style={{ height: "inherit" }}
                          src={`/assets/img/${onboardingData[step].rightCenterBackground}`}
                          alt="SaiY"
                        />
                        <img
                          style={{ height: "270px" }}
                          src={`/assets/img/${onboardingData[step].rightCenter}`}
                          alt="SaiY"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="onboard-bottom-row-right">
                <img
                  src="/assets/img/onboard1-bottom.png"
                  style={{ width: "5rem" }}
                  alt="SaiY"
                />
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100vh" }}
        >
          <div style={{ flex: 1, backgroundColor: "#f0f0f0", padding: "20px" }}>
            <div className="logo">
              <img src="/assets/img/saiy-logo.png" alt="SaiY" />
            </div>
            <div className="onboard-top-center-txt">
              <img
                src={`/assets/img/${onboardingData[step].leftCenter}`}
                style={{ width: "-webkit-fill-available" }}
                alt="SaiY"
              />
            </div>
            <div className="onboard-top-row">
              <label onClick={() => window.location.replace("login")}>
                Skip
              </label>
              <img
                src={`/assets/img/${onboardingData[step].nextBtn}`}
                onClick={() => handleClick()}
                alt="SaiY"
              />
            </div>
          </div>
          <div style={{ flex: 1, backgroundColor: "#035793", padding: "20px" }}>
            <div className="onboard-right-top">
              {onboardingData[step].rightTop}
            </div>
            {step === 0 ? (
              <div className="onboard-bottom-center-txt">
                <img
                  src={`/assets/img/${onboardingData[step].rightCenter}`}
                  alt="SaiY"
                />
              </div>
            ) : (
              <div>
                {step === 0 ? (
                  <img
                    src={`/assets/img/${onboardingData[step].rightCenter}`}
                    alt="SaiY"
                  />
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <div className="bottom-center">
                      <img
                        style={{ height: "inherit" }}
                        src={`/assets/img/${onboardingData[step].rightCenterBackground}`}
                        alt="SaiY"
                      />
                      <img
                        style={{ height: "230px" }}
                        src={`/assets/img/${onboardingData[step].rightCenter}`}
                        alt="SaiY"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="onboard-bottom-row-right">
              <img
                src="/assets/img/onboard1-bottom.png"
                style={{ width: "5rem" }}
                alt="SaiY"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OnBoarding;
