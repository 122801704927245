import React, { useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";

const Statistics = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div>
      <div>
        <Sidebar open={isDrawerOpen} onClose={handleDrawerToggle} />
      </div>
      <div className="custom-side">
        <div className="setting-body">
          <h2>Activity Status</h2>
          <div className="statistics">
            <img src="/assets/img/statistics.png" alt="SaiY" />
          </div>
          <label className="statistics-lbl">
            This feature will be available soon.
          </label>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
