import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import PricingCard from "../pricingPlan/pricing";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { BaseService } from "../../service";

const Subscription = () => {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const service = new BaseService();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const footerContent = (
    <div className="gotit-btn">
      <Button
        label="OK, GOT IT!"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  const headerContent = (
    <div style={{ textAlign: "center" }}>
      <img src="/assets/img/goodTick.svg" alt="Ok" />
    </div>
  );

  const headerContent1 = (
    <div style={{ textAlign: "center" }}>
      <img src="/assets/img/cancelSubscrition.png" alt="Ok" />
    </div>
  );

  const footerContent1 = (
    <div className="gotit-btn">
      <Button
        label="Go Back"
        style={{ backgroundColor: "white", color: "#035793" }}
        icon="pi pi-check"
        onClick={() => setVisible1(false)}
        autoFocus
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          setVisible(true);
          setVisible1(false);
        }}
        autoFocus
      />
    </div>
  );

  const navigate = useNavigate();

  const handleGoToPlan = () => {
    navigate("/payment-method", { state: { advanceId } });
  };

  const [advanceId, setAdvanceId] = useState();

    const [profilData, setprofilData] = useState(null);

  const getProfileData = async () => {
    const profileData = await service.getProfile();
    if (profileData?.data?.message == "Success") {
      setprofilData(profileData?.data?.data);
    }
  };

  const handleSetAdvance = (id) => {
    setAdvanceId(id);
  };
  const handleSubscription = async () => {
    const response = await service.getSubscription();
    if (response?.data?.message) {
      // navigate("/dashboard");
    }
  };
  
  useEffect(() => {
    handleSubscription();
    getProfileData();
  }, []);

  if(profilData?.company_id) {
    navigate("/settings");
  }

  return (
    <>
      <div>
        <div>
          <Sidebar open={isDrawerOpen} onClose={handleDrawerToggle} />
        </div>
        <div className="custom-side">
          <div className="setting-body">
            <div className="d-flex align-items-center">
              <button
                onClick={handleDrawerToggle}
                className="icon-button desktop-hide"
              >
                <img
                  src="/assets/img/menu.png"
                  alt="Menu Icon"
                  className="icon"
                />
              </button>
              <h2 className="subscription-h2">Subscriptions</h2>
            </div>

            <div className="subscription-plan">
              <label>Get more of SAiY Subscription Plan</label>

              <PricingCard
                headingDisplay={1}
                handleSetAdvance={(id) => handleSetAdvance(id)}
              />

              <div className="subscription-btn">
                <button onClick={() => handleGoToPlan()}>
                  {" "}
                  Continue with Advance Plan
                </button>
              </div>

              <div className="subscription-btn">
                <button
                  onClick={() => setVisible1(true)}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    marginBottom: "50px",
                  }}
                >
                  Cancel Subscription
                </button>
              </div>
              <Dialog
                header={headerContent1}
                visible={visible1}
                style={{ width: "27vw", textAlign: "center" }}
                onHide={() => setVisible1(false)}
                footer={footerContent1}
              >
                <h3>Cancel Subscription?</h3>
                <p className="m-0">
                  Are you sure you want to cancel current Subscription Plan?
                </p>
              </Dialog>

              <Dialog
                header={headerContent}
                visible={visible}
                style={{ width: "27vw", textAlign: "center" }}
                onHide={() => setVisible(false)}
                footer={footerContent}
              >
                <h3>Subscription Cancelled</h3>
                <p className="m-0">
                  We hope you resubscribe soon. Best of Luck!
                </p>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
