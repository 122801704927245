import { useState } from "react";
import { BaseService } from "../../service";
import { useLocation, useNavigate } from "react-router-dom";

const NewPassword = () => {


    const [password, setPassword] = useState();
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const service = new BaseService();
    const location = useLocation();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setSubmit(true);
        setLoading(true);
        try {
            const resetPassword = await service.recoverPassword({ password }, location.state.otp);
            if (resetPassword && resetPassword.data) {
                navigate('/login')
            }
        } catch (error) {

        }
    }

    return (
        <div style={{ textAlign: 'center' }}>

            <div className='logo'>
                <img src="/assets/img/saiy-logo.png" alt='SaiY' />
            </div>

            <br />
            <br />
            <br />

            <h1 className="login-heading">Create New Password</h1>

            <p className="login-subheading">Please enter your new password here</p>

            <div className="login-form">
                <label>New Password</label>
                <input onChange={(e) => setPassword(e.target.value)} type="password" title="Enter password" style={{ borderColor: `${submit && !password ? 'red' : '#F7F7F9'}` }} />
                {submit && !password &&
                    <label style={{ color: "red", marginTop: 0 }}>Please enter password.</label>
                }
                <button style={{ backgroundColor: `${password ? '#035793' : '#7D848D'}` }} onClick={() => handleSubmit()}>Sign in</button>
            </div>
            {loading && <div id="loader">
            </div>}
        </div>
    );


};

export default NewPassword;