import { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Stripe from "./Stripe";

const AddNewCard = () => {
  const [cardHolderName, setcardHolderName] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [cvv, setCvv] = useState();
  const [submit, setSubmit] = useState(false);

  const handleSubmit = () => {
    setSubmit(true);
    cardHolderName && cardNumber && expiryDate && cvv && setVisible(true);
    // cardHolderName && window.location.replace('otp')
  };

  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div className="gotit-btn">
      <Button
        label="OK, GOT IT!"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  const headerContent = (
    <div style={{ textAlign: "center" }}>
      <img src="/assets/img/goodTick.svg" alt="Ok" />
    </div>
  );

  return (
    <div style={{ textAlign: "center" }}>
      <div className="logo">
        <img src="/assets/img/saiy-logo.png" alt="SaiY" />
      </div>
      <br />
      <h1 className="login-heading">Add New Card</h1>
      <p className="login-subheading">Add your card details</p>
      {/* <div className="new-card-form">
        <input
          placeholder="Card Holder Name"
          onChange={(e) => setcardHolderName(e.target.value)}
          type="text"
          title="Card Holder Name"
          style={{
            borderColor: `${submit && !cardHolderName ? "red" : "#F7F7F9"}`,
          }}
        />
        {submit && !cardHolderName && (
          <label style={{ color: "red", marginTop: 0 }}>
            Please enter Card Holder Name.
          </label>
        )}
        <input
          placeholder="Card Number"
          onChange={(e) => setCardNumber(e.target.value)}
          type="text"
          title="Card Number"
          style={{
            borderColor: `${submit && !cardNumber ? "red" : "#F7F7F9"}`,
          }}
        />
        {submit && !cardNumber && (
          <label style={{ color: "red", marginTop: 0 }}>
            Please enter Card Number.
          </label>
        )}
          <button className="scan">
            <img src="/assets/img/scan.png" alt="Scan" />
            SCAN CARD
          </button>
        <div className="card-secret">
          <div className="expiry">
            <input
              placeholder="Exp. Date (MM/YY)"
              onChange={(e) => setExpiryDate(e.target.value)}
              type="text"
              title="Exp. Date (MM/YY)"
              style={{
                borderColor: `${submit && !expiryDate ? "red" : "#F7F7F9"}`,
              }}
            />
            {submit && !expiryDate && (
              <label style={{ color: "red", marginTop: 0 }}>
                Please enter Expiry date.
              </label>
            )}
          </div>
          <div style={{ display: "grid" }}>
            <input
              placeholder="CVV Number"
              onChange={(e) => setCvv(e.target.value)}
              type="text"
              title="CVV Number"
              style={{ borderColor: `${submit && !cvv ? "red" : "#F7F7F9"}` }}
            />
            {submit && !cvv && (
              <label style={{ color: "red", marginTop: 0 }}>
                Please enter CVV Number.
              </label>
            )}
          </div>
        </div>
        <Dialog
          header={headerContent}
          visible={visible}
          style={{ width: "27vw", textAlign: "center" }}
          onHide={() => setVisible(false)}
          footer={footerContent}
        >
          <h3>Payment Confirmed</h3>
          <p className="m-0">
            Unleash the Future of Typing with our AI Keyboard Extension. Best of
            Luck!
          </p>
        </Dialog>
        <Stripe />
        <button
          className="proceed-to-pay"
          style={{
            backgroundColor: `${
              cardHolderName && cardNumber && expiryDate && cvv
                ? "#035793"
                : "#7D848D"
            }`,
          }}
          onClick={() => handleSubmit()}
        >
          Proceed To Pay
        </button>
      </div> */}
      <div className="new-card-form">
        <Stripe />
      </div>
    </div>
  );
};

export default AddNewCard;
