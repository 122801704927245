import { useState } from "react";
import { BaseService } from "../../service";

const Login = () => {
  const service = new BaseService();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setSubmit(true);
    setLoading(true);
    try {
      const login = await service.Login({ email, password });
      setLoading(false);
      login.data &&
        email &&
        password &&
        window.location.replace("subscriptions");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div className="logo">
        <img src="/assets/img/saiy-logo.png" alt="SaiY" />
      </div>
      <br />
      <h1 className="login-heading">Login into your account</h1>
      <p className="login-subheading">Please fill the details and sign in</p>

      <div className="login-form">
        <label>Email Address</label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          title="Email address"
          style={{ borderColor: `${submit && !email ? "red" : "#F7F7F9"}` }}
        />
        {submit && !email && (
          <label style={{ color: "red", marginTop: 0 }}>
            Please enter email address.
          </label>
        )}
        <label>Password</label>
        <input
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          title="Enter Password"
          style={{ borderColor: `${submit && !password ? "red" : "#F7F7F9"}` }}
        />
        {submit && !password && (
          <label style={{ color: "red", marginTop: 0 }}>
            Incorrect Password. Please enter the correct password.
          </label>
        )}
        <label
          onClick={() => window.location.replace("reset-password")}
          style={{
            textAlign: "end",
            cursor: "pointer",
            color: "#035793",
            marginTop: "5px",
            marginRight: "5px",
          }}
        >
          Forgot Password?
        </label>
        <button
          style={{
            backgroundColor: `${email && password ? "#035793" : "#7D848D"}`,
          }}
          onClick={() => {
            email && password && handleSubmit();
          }}
        >
          Sign in
        </button>
      </div>

      <div className="login-bottom-txt">
        <label>Don’t have an account? </label>
        <span onClick={() => window.location.replace("signup")}>
          &nbsp;Register for free
        </span>
      </div>
      {loading && <div id="loader">{/* <ProgressSpinner /> */}</div>}
    </div>
  );
};

export default Login;
