import { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { BaseService } from "../../service";

const SignUp = () => {
  const service = new BaseService();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [submit, setSubmit] = useState(false);
  const [address] = useState("");
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setSubmit(true);
    setLoading(true);
    console.log(value, "  phone value");
    try {
      if (email && password && password === confirmPassword) {
        const data = await service.SignUp({
          email,
          password,
          address,
          phone: value,
        });
        if (data) {
          setLoading(false);
          window.location.replace("settings?newUser=true");
          console.log(data, "  here is signup");
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error, "  here is signup error ");
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div className="logo">
        <img src="/assets/img/saiy-logo.png" alt="SaiY" />
      </div>

      <br />

      <h1 className="login-heading">Create an account</h1>

      <p className="login-subheading">Please fill the details and sign up</p>

      <div className="login-form">
        <label>Email</label>

        <input
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          title="Email address"
          style={{ borderColor: `${submit && !email ? "red" : "#F7F7F9"}` }}
        />

        {submit && !email && (
          <label style={{ color: "red", marginTop: 0 }}>
            Please enter email address.
          </label>
        )}

        <label>Password</label>

        <input
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          title="Enter Password"
          style={{ borderColor: `${submit && !password ? "red" : "#F7F7F9"}` }}
        />
        {submit && !password && (
          <label style={{ color: "red", marginTop: 0 }}>
            Incorrect Password. Please enter the correct password.
          </label>
        )}

        <label>Confirm Password</label>

        <input
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          title="Enter Password"
          style={{ borderColor: `${submit && !password ? "red" : "#F7F7F9"}` }}
        />

        {submit && !confirmPassword && password !== confirmPassword && (
          <label style={{ color: "red", marginTop: 0 }}>
            Incorrect Password. Please enter the same password as above.
          </label>
        )}

        <label>Phone Number</label>

        <PhoneInput
          className="phone-input"
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
        />

        {submit && !value && (
          <label style={{ color: "red", marginTop: 0 }}>
            Incorrect Phone Number. Please enter the valid phone number.
          </label>
        )}

        {/* <label>Address</label>
        <textarea
          title="Add Address Here..."
          name="postContent"
          rows={4}
          cols={40}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          style={{ borderColor: `${submit && !address ? "red" : "#F7F7F9"}` }}
        /> */}

        {/* {submit && !address && (
          <label style={{ color: "red", marginTop: 0 }}>
            Address not found. Please enter the valid address.
          </label>
        )} */}

        <label
          onClick={() => window.location.replace("reset-password")}
          style={{
            textAlign: "end",
            cursor: "pointer",
            color: "#035793",
            marginTop: "5px",
            marginRight: "5px",
          }}
        >
          Forgot Password?
        </label>

        <button
          style={{
            backgroundColor: `${
              email &&
              password &&
              confirmPassword &&
              value &&
              password === confirmPassword
                ? "#035793"
                : "#7D848D"
            }`,
          }}
          onClick={() => handleSubmit()}
        >
          Sign up
        </button>

        <div className="signup-bottom-txt">
          <label>Already have an account?</label>
          <span onClick={() => window.location.replace("login")}>
            &nbsp;Sign in Now
          </span>
        </div>
      </div>
      {loading && <div id="loader">{/* <ProgressSpinner /> */}</div>}
    </div>
  );
};

export default SignUp;
