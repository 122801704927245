import React, { useState, useEffect } from "react";
import { BaseService, formatDate, formatTimeRange } from "../../service";

const Current = () => {
  const service = new BaseService();
  const [currentHistory, setCurrentHistory] = useState();

  const handleHistoryData = async () => {
    const response = await service.getPaymentHistory("current");
    if (response.status === 200) {
      setCurrentHistory(response?.data?.data);
    }
  };

  handleHistoryData();

  return (
    <>
      <div className="language">
        <div>
          {currentHistory ? (
            currentHistory.map((item) => {
              return (
                <table className="payment-history">
                  <tr>
                    <th style={{ paddingLeft: "50px" }}>Status</th>
                    <th>Plan</th>
                    <th>Date</th>
                    <th>Time</th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "50px",
                        color: `${
                          item.status === "Confirmed" ? "#346FE7" : "#BB2C3D"
                        }`,
                      }}
                    >
                      {item.status}
                    </td>
                    <td>Pro</td>
                    <td>{formatDate(item.created_at)}</td>
                    <td>{formatTimeRange(item.created_at)}</td>
                  </tr>
                </table>
              );
            })
          ) : (
            <div>Sorry you don't have any payment.</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Current;
