import { useEffect } from "react";
import { useState } from "react";
import OtpInput from 'react-otp-input';
import { BaseService } from "../../service";
import { useLocation, useNavigate } from "react-router-dom";
import swal from 'sweetalert';

const OTP = () => {

    const service = new BaseService();
    const navigate = useNavigate();
    const location = useLocation();
    const [otp, setOtp] = useState('');
    const [, setSubmit] = useState(false);

    const [delay, setDelay] = useState(30);
    const seconds = Math.floor(delay % 60);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const timer = setInterval(() => {
            setDelay(delay - 1);
        }, 1000);

        if (delay === 0) {
            clearInterval(timer);
        }

        return () => {
            clearInterval(timer);
        };
    });

    const handleSubmit = async () => {

        setSubmit(true);
        setLoading(true);

        try {
            const verify = await service.verifyOtp(otp);
            console.log(verify, '  verified');

            if (verify && verify.data) {
                setLoading(false);
                navigate('/new-password', { state: { otp, email: location.state.email } })
            }


        } catch (error) {
            setLoading(false);
            alert(error);
            console.log(error, '  error in verify');
        }
    }

    const handleSendAgain = async () => {

        setLoading(true);

        try {
            const resendEmail = await service.forgotPassword({ email: location.state.email });
            console.log(resendEmail, '  resendEmail');

            if (resendEmail && resendEmail.data) {
                setLoading(false);
                swal("OTP", "OTP sent successfully", "success");
                window.location.reload();
            }


        } catch (error) {
            setLoading(false);
            swal("Sorry!", "Error in sending email", "error");
            console.log(error, '  error in verify');
        }
    }

    return (
        <div style={{ textAlign: 'center' }}>

            <div className='logo'>
                <img src="/assets/img/saiy-logo.png" alt='SaiY' />
            </div>

            <br />
            <br />
            <br />

            <h1 className="login-heading">Enter OTP</h1>

            <p className="login-subheading">Please enter your email address</p>

            <div className="otp-input">
                <OtpInput
                    inputStyle={{ width: "50px", height: "40px", backgroundColor: "#F7F7F9", borderRadius: "12px", border: '1px solid #F7F7F9' }}
                    containerStyle={{ display: "block", textAlign: 'center' }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                />
                <label style={{ textAlign: "center" }}>00:{seconds}{seconds === 0 ? '0' : ''}</label>
                <br />
                <div style={{ textAlign: 'center' }}>
                    <label>Received Nothing? </label>
                    <button disabled={seconds === 0 ? false : true} onClick={() => handleSendAgain()} style={{ color: "#035793", border: 'none', background: 'transparent' }}>Send Again</button>
                </div>
                <button disabled={otp.length < 4 ? true : false} style={{ opacity: otp.length < 4 ? 0.5 : 1 }} className="otp-verify-btn" onClick={() => handleSubmit()}>Verify</button>
            </div>
            {loading && <div id="loader">
            </div>}
        </div>
    );


};

export default OTP;